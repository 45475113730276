<template>
  <div class="youth-management" v-if="hasPerm('youth.view_seanceinscription')">
    <div class="youth-management-table" :id="'youth-management-table-' + id">
      <div class="filter-bar no-print">
        <b-row>
          <b-col cols="8">
            <seances-list-filter
              @changed="onListFilterChanged($event)"
              @loaded="onListFilterLoaded($event)"
              stacked
              multi
              :id="id"
            >
            </seances-list-filter>
          </b-col>
          <b-col>
            <div>
              <date-frame-selector @change="onDateFrameChanged">
              </date-frame-selector>
              <div class="custom-range-holder no-print">
                <div><b>Tranches de QF</b></div>
                <b-row>
                  <b-col>
                    <number-input v-model="familyLevel1" id="familyLevel1" allow-null @focusout="loadData()">
                    </number-input>
                  </b-col>
                  <b-col>
                    <number-input v-model="familyLevel2" id="familyLevel2" allow-null @focusout="loadData()">
                    </number-input>
                  </b-col>
                </b-row>
              </div>
              <div class="custom-range-holder no-print">
                <div><b>Âge</b></div>
                <b-row>
                  <b-col>
                    <number-input v-model="age1" id="age1" allow-null @focusout="loadData()">
                    </number-input>
                  </b-col>
                  <b-col>
                    <number-input v-model="age2" id="age2" allow-null @focusout="loadData()">
                    </number-input>
                  </b-col>
                </b-row>
              </div>
            </div>
          </b-col>
        </b-row>
      </div>
      <div>
        <loading-gif :loading-name="loadingName"></loading-gif>
        <div v-if="holidayAnalytics.length === 0 && schoolDayAnalytics.length === 0" class="empty-text">
          <div v-if="isValid">Aucune données disponibles pour ce filtre</div>
          <div v-else>Veuillez sélectionner via le filtre les données à afficher</div>
        </div>
        <div v-else class="caf-warning-text">
          <i class="fa fa-warning"></i>
          Les montants des participations CAF ne sont qu'une estimation et sont calculées à partir
          de la durée de base des séances et non pas du pointage ou de la durée retenue par la CAF.
          <br />
          <i class="fa fa-warning"></i> Ce tableau prend en compte les annulations facturées
        </div>
        <div v-if="holidayAnalytics.length" class="section">
          <b-row><b-col><div class="section-header"><b>{{ subTitle(false) }}</b></div></b-col></b-row>
          <b-row>
            <b-col>
              <table class="full-table table-bordered table-striped">
                <tr>
                  <th></th>
                  <th>Nb jours</th>
                  <th>Journées</th>
                  <th>Particip. familles</th>
                  <th>Prix journée</th>
                  <th>PS CAF/MSA</th>
                  <th>Nb d'heures</th>
                  <th>Budget total</th>
                  <th>Budget journée</th>
                </tr>
                <tr v-for="item of holidayAnalytics" :key="item.analyticAccount.id">
                  <th>{{ item.analyticAccount.getLabel() }}</th>
                  <td class="number">{{ item.daysCount }}</td>
                  <td class="number">{{ item.inscriptionsCount }}</td>
                  <td class="number">{{ item.amount | currency }}</td>
                  <td class="number">{{ item.averageAmount | currency }}</td>
                  <td class="number">{{ item.cafAmount | currency }}</td>
                  <td class="number">{{ item.duration }}</td>
                  <td class="number">{{ item.totalAmount | currency }}</td>
                  <td class="number">{{ item.averageTotalAmount | currency }}</td>
                </tr>
                <tr>
                  <th></th>
                  <th class="number">{{ sumItems(holidayAnalytics, 'daysCount') }}</th>
                  <th class="number">{{ sumItems(holidayAnalytics, 'inscriptionsCount') }}</th>
                  <th class="number">{{ sumItems(holidayAnalytics, 'amount')| currency }}</th>
                  <th class="number">{{ avgItems(holidayAnalytics, 'amount', 'inscriptionsCount') | currency }}</th>
                  <th class="number">{{ sumItems(holidayAnalytics, 'cafAmount')| currency }}</th>
                  <th class="number">{{ sumItems(holidayAnalytics, 'duration') }}</th>
                  <th class="number">{{ sumItems(holidayAnalytics, 'totalAmount')| currency }}</th>
                  <th class="number">{{ avgItems(holidayAnalytics, 'totalAmount', 'inscriptionsCount') | currency }}</th>
                </tr>
              </table>
            </b-col>
            <b-col cols="4">
              <table class="full-table table-bordered table-striped">
                <tr>
                  <th></th>
                  <th>Montant</th>
                  <th>Nb enfants</th>
                  <th>Nb journée</th>
                  <th>Prix journée</th>
                </tr>
                <tr v-for="item of holidaySummary" :key="item.label">
                  <th>{{ item.label }}</th>
                  <td class="number">{{ item.amount | currency }}</td>
                  <td class="number">{{ item.individuals }}</td>
                  <td class="number">{{ item.days }}</td>
                  <td class="number">{{ item.dailyAmount | currency }}</td>
                </tr>
                <tr>
                  <th></th>
                  <th class="number">{{ holidaysAmount | currency }}</th>
                  <th class="number">{{ holidayChildren }}</th>
                  <th class="number">{{ holidayDays }}</th>
                  <th class="number">{{ holidaysDailyAmount | currency }}</th>
                </tr>
              </table>
            </b-col>
          </b-row>
        </div>
        <div v-if="schoolDayAnalytics.length" class="section">
          <b-row><b-col><div class="section-header"><b>{{ subTitle(true) }}</b></div></b-col></b-row>
          <b-row>
            <b-col>
              <table class="full-table table-bordered table-striped">
                <tr>
                  <th></th>
                  <th>Nb jours</th>
                  <th>Nb d'heures</th>
                  <th>Participation des familles</th>
                  <th>Prix horaire</th>
                  <th>PS CAF/MSA</th>
                  <th>Nb d'heures</th>
                  <th>Budget total</th>
                  <th>Budget journée</th>
                </tr>
                <tr v-for="item of schoolDayAnalytics" :key="item.analyticAccount.id">
                  <th>{{ item.analyticAccount.getLabel() }}</th>
                  <td class="number">{{ item.daysCount }}</td>
                  <td class="number">{{ item.paidDuration }}</td>
                  <td class="number">{{ item.amount | currency }}</td>
                  <td class="number">{{ item.averageAmount | currency }}</td>
                  <td class="number">{{ item.cafAmount | currency }}</td>
                  <td class="number">{{ item.duration }}</td>
                  <td class="number">{{ item.totalAmount | currency }}</td>
                  <td class="number">{{ item.averageTotalAmount | currency }}</td>
                </tr>
                <tr>
                  <th></th>
                  <th class="number">{{ sumItems(schoolDayAnalytics, 'daysCount') }}</th>
                  <th class="number">{{ sumItems(schoolDayAnalytics, 'paidDuration') }}</th>
                  <th class="number">{{ sumItems(schoolDayAnalytics, 'amount')| currency }}</th>
                  <th class="number">{{ avgItems(schoolDayAnalytics, 'amount', 'duration') | currency }}</th>
                  <th class="number">{{ sumItems(schoolDayAnalytics, 'cafAmount')| currency }}</th>
                  <th class="number">{{ sumItems(schoolDayAnalytics, 'duration') }}</th>
                  <th class="number">{{ sumItems(schoolDayAnalytics, 'totalAmount')| currency }}</th>
                  <th class="number">{{ avgItems(schoolDayAnalytics, 'totalAmount', 'duration') | currency }}</th>
                </tr>
              </table>
            </b-col>
            <b-col cols="4">
              <table class="full-table table-bordered table-striped">
                <tr>
                  <th></th>
                  <th>Montant</th>
                  <th>Nb d'heures</th>
                  <th>Prix horaire</th>
                </tr>
                <tr v-for="item of schoolDaySummary" :key="item.label">
                  <th>{{ item.label }}</th>
                  <td class="number">{{ item.amount | currency }}</td>
                  <td class="number">{{ item.duration }}</td>
                  <td class="number">{{ item.hourlyAmount | currency }}</td>
                </tr>
                <tr>
                  <th></th>
                  <th class="number">{{ schoolDayAmount | currency }}</th>
                  <th class="number">{{ sumItems(schoolDaySummary, 'duration') }}</th>
                  <th class="number">{{ schoolDayHourlyAmount | currency }}</th>
                </tr>
              </table>
            </b-col>
          </b-row>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import { mapActions, mapMutations } from 'vuex'
import LoadingGif from '@/components/Controls/LoadingGif'
import SeancesListFilter from '@/components/Seances/Config/SeancesListFilter'
import DateFrameSelector from '@/components/DateRange/DateFrameSelector.vue'
import NumberInput from '@/components/Controls/NumberInput.vue'
import { BackendMixin } from '@/mixins/backend'
import { dateToString } from '@/filters/texts'
import { BackendApi } from '@/utils/http'
import { sum } from '@/utils/math'
import { makeAnalyticAccount } from '@/types/payments'

export default {
  name: 'youth-management-table',
  mixins: [BackendMixin],
  components: {
    NumberInput,
    LoadingGif,
    SeancesListFilter,
    DateFrameSelector,
  },
  props: {
    id: String,
  },
  data() {
    return {
      youthHomes: [],
      seanceTypes: [],
      periods: [],
      familyLevel1: null,
      familyLevel2: null,
      age1: null,
      age2: null,
      startDate: null,
      endDate: null,
      analyticAccounts: [],
      schoolDayAnalytics: [],
      holidayAnalytics: [],
      schoolDaySummary: [],
      holidaySummary: [],
      holidayChildren: 0,
      holidayDays: 0,
      schoolDayDays: 0,
    }
  },
  computed: {
    holidaysAmount() {
      return this.sumItems(this.holidaySummary, 'amount')
    },
    holidaysDailyAmount() {
      if (this.holidayDays) {
        return this.holidaysAmount / this.holidayDays
      } else {
        return null
      }
    },
    schoolDayAmount() {
      return this.sumItems(this.schoolDaySummary, 'amount')
    },
    schoolDayHourlyAmount() {
      const totalDuration = this.sumItems(this.schoolDaySummary, 'duration')
      if (totalDuration) {
        return this.schoolDayAmount / totalDuration
      } else {
        return null
      }
    },
    isValid() {
      return (
        this.youthHomes.length && this.seanceTypes.length && this.periods.length
      )
    },
    loadingName() {
      return 'youth-management-' + this.id
    },
  },
  mounted() {
  },
  watch: {
    id: function() {},
  },
  methods: {
    ...mapActions(['addError', 'addSuccess']),
    ...mapMutations(['startLoading', 'endLoading']),
    async loadData() {
      this.disabled = true
      this.startLoading(this.loadingName)
      await this.loadStats()
      this.endLoading(this.loadingName)
      this.disabled = false
    },
    subTitle(schoolDay) {
      let texts = []
      const seanceTypes = this.seanceTypes.filter(elt => elt.schoolDay === schoolDay)
      if (this.youthHomes) {
        texts = texts.concat(this.youthHomes.map(elt => elt.name))
      }
      if (seanceTypes.length) {
        texts = texts.concat(seanceTypes.map(elt => elt.name))
      }
      texts = texts.concat(this.periods.map(elt => elt.name))
      return texts.join(' - ')
    },
    makeSchoolDaySummary(label, elt) {
      const amount = (+elt['total_amount'] || 0)
      return {
        label: label,
        amount: amount,
        duration: elt.duration,
        days: elt.days,
        dailyAmount: elt.days ? (amount / elt.days) : null,
        hourlyAmount: elt.duration ? (amount / elt.duration) : null,
      }
    },
    filterSchoolDaySummary(items) {
      return items.filter(elt => elt.duration > 0)
    },
    makeHolidaySummary(label, elt) {
      const amount = (+elt['total_amount'] || 0)
      return {
        label: label,
        amount: amount,
        individuals: elt.individuals,
        days: elt.days,
        dailyAmount: elt.days ? (amount / elt.days) : null,
      }
    },
    filterHolidaySummary(items) {
      return items.filter(elt => elt.individuals > 0)
    },
    buildAnalyticsData(respData) {
      const analytics = respData.analytics
      const analyticAccounts = respData['analytic_accounts'].map(elt => makeAnalyticAccount(elt))
      const analyticAccountsMap = new Map()
      for (const analyticAccount of analyticAccounts) {
        analyticAccountsMap.set(+analyticAccount.id, analyticAccount)
      }
      const schoolDayData = []
      const holidayData = []

      for (const item of analytics) {
        let analyticAccount = analyticAccountsMap.get(+item['analytic_account']) || null
        if (!analyticAccount) {
          analyticAccount = makeAnalyticAccount()
        }
        const amount = (+item['total_amount'] || 0)
        const cafAmount = (+item['total_caf_amount'] || 0)
        const daysCount = item['days_count']
        const isSchoolDay = item['seance__seance_type__school_day']
        const duration = item['duration_total'] // Durée des séances
        const paidDuration = item['paid_duration'] // Durée des séances
        if (isSchoolDay) {
          const newItem = {
            analyticAccount: analyticAccount,
            daysCount: daysCount,
            amount: amount,
            duration: duration,
            paidDuration: paidDuration,
            averageAmount: paidDuration ? Math.round(100 * amount / paidDuration) / 100 : '',
            cafAmount: cafAmount,
            totalAmount: amount + cafAmount,
            averageTotalAmount: duration ? Math.round(100 * (amount + cafAmount) / paidDuration) / 100 : '',
          }
          schoolDayData.push(newItem)
        } else {
          const halfsCount = (+item['halfs_count'] || 0) + 2 * (+item['fulls_count'] || 0)
          const insDays = Math.round(halfsCount * 5) / 10
          const newItem = {
            analyticAccount: analyticAccount,
            daysCount: daysCount,
            inscriptionsCount: insDays,
            duration: duration,
            amount: amount,
            averageAmount: insDays ? Math.round(100 * amount / insDays) / 100 : '',
            cafAmount: cafAmount,
            totalAmount: amount + cafAmount,
            averageTotalAmount: insDays ? Math.round(100 * (amount + cafAmount) / insDays) / 100 : '',
          }
          holidayData.push(newItem)
        }
      }
      this.schoolDayAnalytics = schoolDayData
      this.holidayAnalytics = holidayData
    },
    async loadStats() {
      if (this.isValid) {
        let url = '/stats/api/youth-management/'
        let data = {
          'youth_homes': this.youthHomes.map(elt => elt.id),
          'seance_types': this.seanceTypes.map(elt => elt.id),
          'periods': this.periods.map(elt => elt.id),
          'start_date': this.startDate ? dateToString(this.startDate, 'YYYY-MM-DD') : null,
          'end_date': this.endDate ? dateToString(this.endDate, 'YYYY-MM-DD') : null,
        }
        if (this.familyLevel1 || this.familyLevel2) {
          data.family_levels = [this.familyLevel1, this.familyLevel2]
        }
        if (this.age1 || this.age2) {
          data.ages = [this.age1, this.age2]
        }
        const backendApi = new BackendApi('post', url)
        try {
          const resp = await backendApi.callApi(data)
          this.buildAnalyticsData(resp.data)
          this.schoolDaySummary = this.filterSchoolDaySummary(
            [
              this.makeSchoolDaySummary('Annulations', resp.data['school_day_cancellations']),
              this.makeSchoolDaySummary('Matins', resp.data['school_day_mornings']),
              this.makeSchoolDaySummary('Repas', resp.data['school_day_lunches']),
              this.makeSchoolDaySummary('Soirs', resp.data['school_day_afternoons']),
              this.makeSchoolDaySummary('Forfaits', resp.data['school_day_fixed_fee'])
            ]
          )
          this.holidaySummary = this.filterHolidaySummary(
            [
              this.makeHolidaySummary('Annulations', resp.data['holiday_cancellations']),
              this.makeHolidaySummary('Journées avec repas', resp.data['holiday_day_lunch']),
              this.makeHolidaySummary('Journées sans repas', resp.data['holiday_day_no_lunch']),
              this.makeHolidaySummary('Demies-journées avec repas', resp.data['holiday_half_lunch']),
              this.makeHolidaySummary('Demies-Journées sans repas', resp.data['holiday_half_no_lunch']),
              this.makeHolidaySummary('Sorties', resp.data['holiday_trip']),
              this.makeHolidaySummary('Séjours', resp.data['holiday_stay']),
              this.makeHolidaySummary('Soirées', resp.data['holiday_evenings']),
              this.makeHolidaySummary('Forfaits', resp.data['holiday_fixed_fee'])
            ]
          )
          this.holidayChildren = resp.data['holiday_children']
          this.holidayDays = resp.data['holiday_days']
        } catch (err) {
          await this.addError(this.getErrorText(err))
        }
      } else {
        this.schoolDayAnalytics = []
        this.holidayAnalytics = []
      }
    },
    onListFilterChanged(evt) {
      if (evt.id === this.id) {
        this.youthHomes = evt.youthHomes
        this.seanceTypes = evt.seanceTypes
        this.periods = evt.periods
        this.loadData()
      }
    },
    onChange() {
      this.loadData()
    },
    onListFilterLoaded(evt) {
    },
    onDateFrameChanged(event) {
      this.startDate = event.startDate
      this.endDate = event.endDate
      this.loadData()
    },
    sumItems(items, field) {
      return Math.round(100 * sum(items.map(elt => elt[field]))) / 100
    },
    avgItems(items, field1, field2) {
      const duration = sum(items.map(elt => elt[field2]))
      if (duration) {
        const total = sum(items.map(elt => elt[field1]))
        return Math.round(100 * total / duration) / 100
      }
      return ''
    },
  },
}
</script>

<style scoped lang="less">
.youth-management-table {
  .section {
    margin: 10px 0;
  }
  .section-header {
    background: #ccc;
    padding: 5px;
  }

  table.full-table {
    width: 100%;
  }

  table.full-table td, table.full-table th {
    padding: 3px 3px;
    font-size: 12px;
  }

  table.full-table th {
    background: #ddd;
  }

  table.full-table tr:nth-child(odd) th {
    background: #ccc;
  }

  table.full-table td.value-col, table.full-table th.value-col {
    max-width: 40px;
    overflow: hidden;
  }

  table.full-table th.header-col {
    font-size: 9px;
  }

  .sub-title {
    margin-bottom: 10px;
    font-weight: bold;
  }

  .number {
    text-align: right;
  }

  .filter-bar {
    background: #e0e0e0;
    padding: 10px 5px;
    border-top: solid 1px #888;
  }

  .filter-bar-optional {
    border-top: solid 1px #888;
    background: #e0e0e0;
    padding: 10px 5px;
  }

  .small-filter-title {
    font-size: 12px;
    margin-bottom: 5px;
  }
  th {
    text-align: center;
  }
  th.number {
    text-align: right;
  }
  .caf-warning-text {
    margin: 10px 0;
    padding: 5px;
    background: #f2f2a2;
    font-size: 14px;
    font-weight: bold;
  }
}
</style>
